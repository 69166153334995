import { useAuth } from "@features/general/auth/state/use-auth";
import { useGlobalEffect } from "@features/utils/hooks/use-global-effect";
import { LoadingState } from "@features/utils/store/loading-state-atom";
import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { ShopLocationClient } from "../api-client/locations-api-client";
import { AuthJWT } from "../jwt";
import { ShopLocation } from "../types";
import {
  CurrentShopLocation,
  FunctionsAuthorizationList,
  ShopLocationList,
} from "./store";

export const useShopLocations = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useShopLocation", false])
  );
  const [locationList, setLocationList] = useRecoilState(ShopLocationList);
  const [currentShopLocation, setCurrentLocation] =
    useRecoilState(CurrentShopLocation);

  const [functionsAuthorization, setFunctionsAuthorization] = useRecoilState(
    FunctionsAuthorizationList
  );

  const refreshLocationList = useCallback(async () => {
    if (!user?.id) return;
    setLoading(true);
    const result = await ShopLocationClient.getLocations(user?.id || "");
    setLocationList(result);
    setLoading(false);
  }, [setLocationList, setLoading, user?.id]);

  const saveCurrentLocation = async (location: ShopLocation | null) => {
    localStorage.setItem("user.location", JSON.stringify(location));
    setCurrentLocation(location);
  };

  const getFunctionsAuthorizations = async (
    profil: string,
    typeLieu: string
  ) => {
    const res = await ShopLocationClient.getFunctionsAuthorizations(
      profil,
      typeLieu
    );

    const returnDict = {} as any;
    res.forEach((el) => {
      if (el.codeTypeProd !== "")
        returnDict[`${el.alias}-${el.codeTypeProd}`] = { ...el };
      else returnDict[`${el.alias}-${el.codeCanalVente}`] = { ...el };
    });
    //console.log(returnDict);
    setFunctionsAuthorization(returnDict);
    persistFunctionAuthorizations(returnDict);
  };

  useGlobalEffect(
    "putConnection",
    () => {
      if (currentShopLocation && user?.id) {
        AuthJWT.codeLieu = currentShopLocation.codeLieu;
        ShopLocationClient.putConnection(
          user?.id,
          currentShopLocation.codeLieu
        );
        persistCodeLieu(currentShopLocation.codeLieu);
      }
    },
    [currentShopLocation, user?.id]
  );

  useGlobalEffect(
    "useShopLocations",
    () => {
      refreshLocationList();
    },
    []
  );

  const persistFunctionAuthorizations = useCallback(
    (updated?: any) => {
      localStorage.setItem(
        "user.functions_authorization",
        JSON.stringify({ ...updated })
      );
    },

    []
  );

  const persistCodeLieu = useCallback(
    (updated?: string) => {
      localStorage.setItem("user.code_lieu", JSON.stringify(updated));
    },

    []
  );

  return {
    loading,
    locations: locationList,
    functionsAuthorization,
    current: currentShopLocation,
    save: saveCurrentLocation,
    setFunctionsAuthorization,
    refresh: refreshLocationList,
    getFunctionsAuthorizations,
  };
};
