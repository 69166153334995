import { Loader } from "@atoms/loader";
import { BaseSmall } from "@atoms/text";
import { applySearchFilter } from "@features/utils/format/strings";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Input, InputProps } from "./input-text";
import { RxCross2 } from "react-icons/rx";
import { MicrophoneIcon } from "@heroicons/react/outline";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

// ... (existing imports)

type InputSuggestionProps = {
  options: { label: string; value: string }[];
  loading?: boolean;
  autoFocus?: "scan" | "keyboard" | boolean;
  deleteButton?: boolean;
  enableMic?: boolean;
  ean?: boolean;
  onClickOption?: (e: any) => void;
} & Omit<InputProps, "autoFocus">;
export const InputWithSuggestions = (props: InputSuggestionProps) => {
  const [focus, setFocus] = useState(false);
  const [micOn, setMicOn] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [hasMicrophone, setHasMicrophone] = useState(false);
  const [speech, setSpeech] = useState<SpeechRecognition | null>(null);
  const value = typeof props.value === "string" ? props.value : "";
  const ref = useRef<HTMLInputElement>(null);
  const filteredOptions = _.uniqBy(
    props.options.filter(
      (e) =>
        applySearchFilter(value, e.label) || applySearchFilter(value, e.value)
    ),
    "value"
  ).filter((e) => e.label?.trim() && e.value?.trim());

  const onKeyDown = (e: any) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        e.stopPropagation();
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, -1));
        break;
      case "ArrowDown":
        e.preventDefault();
        e.stopPropagation();
        setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredOptions.length - 1)
        );
        break;
      case "Enter":
        if (selectedIndex >= 0 && selectedIndex < filteredOptions.length) {
          props.onChange &&
            props.onChange({
              target: { value: filteredOptions[selectedIndex].value },
            } as any);
          setFocus(false);
        }
        break;
      default:
        break;
    }
  };

  const event = useCallback((event: KeyboardEvent) => {
    if (
      event.key?.match(/^[A-Za-z0-9]$/i) &&
      !event.shiftKey &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.altKey &&
      !document.activeElement?.tagName?.match(/input|textarea|select/i)
    ) {
      ref.current?.focus();
    }
  }, []);

  useControlledEffect(() => {
    if (!isFirefox() && props.enableMic) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.onresult = async (event) => {
        const transcript = event.results[0][0].transcript;
        props.onChange &&
          props.onChange({ target: { value: transcript } } as any);
        setMicOn(false);
        const recognitionEndedEvent = new CustomEvent(
          "speechRecognitionDetected",
          {
            detail: { message: "Speech recognition ended", text: transcript },
          }
        );
        window.dispatchEvent(recognitionEndedEvent);
        recognition.stop();
      };

      recognition.onspeechend = () => {
        recognition.stop();
      };

      recognition.onend = () => {
        setMicOn(false);
      };

      // recognition.onerror = (event) => {
      //   console.error("Speech recognition error detected: " + event.error);
      //   recognition.abort();
      // };
      setSpeech(recognition);
    }
  }, []);

  useEffect(() => {
    if (props.autoFocus === true || props.autoFocus === "keyboard") {
      window.addEventListener("keydown", event);
    }
    return () => window.removeEventListener("keydown", event);
  }, [event, props.autoFocus]);

  useEffect(() => {
    setSelectedIndex(-1);
  }, [value]);

  useEffect(() => {
    const checkMicrophone = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasMic = devices.some((device) => device.kind === "audioinput");
        setHasMicrophone(hasMic);
      } catch (error) {
        console.error("Error accessing media devices.", error);
      }
    };

    checkMicrophone();
  }, []);

  return (
    <div className={"relative w-full"} style={props.style}>
      <Input
        inputRef={ref}
        disabled={props.disabled}
        autoFocus={props.autoFocus === "keyboard" || props.autoFocus === true}
        {..._.omit(
          props,
          "options",
          "loading",
          "autoFocus",
          "deleteButton",
          "ean"
        )}
        onFocus={(e) => {
          setFocus(true);
          props.onFocus && props.onFocus(e as any);
        }}
        onBlur={(e) => {
          setFocus(false);
          props.onBlur && props.onBlur(e as any);
        }}
        onChange={(e) => {
          props.onChange &&
            props.onChange({
              target: {
                value: props.ean
                  ? e.target.value.replace("~", "")
                  : e.target.value,
              },
              currentTarget: {
                value: props.ean
                  ? e.target.value.replace("~", "")
                  : e.target.value,
              },
            } as any);
        }}
        onKeyDown={(e) => onKeyDown(e)}
      />
      {props.loading && (
        <div className="absolute top-1/2 right-2 transform -translate-y-1/2 h-full flex items-center">
          <Loader />
        </div>
      )}
      {focus && !!filteredOptions?.length && value.length > 0 && (
        <div className="absolute z-10 top-full left-0 w-full bg-white shadow-md max-h-lg overflow-auto dark:bg-slate-800 rounded-b-lg">
          {_.uniqBy(filteredOptions, "value").map((e: any, index: number) => (
            <div
              key={index}
              className={`py-1 px-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-slate-500 ${
                selectedIndex === index ? "bg-gray-200" : ""
              }`}
              onMouseDown={() => {
                props.onChange &&
                  props.onChange({ target: { value: e.value } } as any);
                props.onClickOption &&
                  props.onClickOption({ target: { value: e.label } } as any);

                setFocus(false);
              }}
            >
              <BaseSmall>{e.label}</BaseSmall>
            </div>
          ))}
        </div>
      )}
      {!isFirefox() && hasMicrophone && value.length < 1 && props.enableMic && (
        <MicrophoneIcon
          className={`h-5 w-5 ${
            micOn ? "text-orange-500 " : "text-slate-400 "
          } opacity-80 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer hover:text-slate-700`}
          onClick={() => {
            if (!micOn) {
              speech?.start();
            } else {
              speech?.abort();
              speech?.stop();
            }
            setMicOn(!micOn);
          }}
        />
      )}
      {!props.disabled && props.deleteButton && value.length > 0 && (
        <RxCross2
          className="h-5 w-5 text-slate-500 opacity-80 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer hover:text-slate-700"
          onClick={() => {
            props.onChange && props.onChange({ target: { value: "" } } as any);
          }}
        />
      )}
    </div>
  );
};

const isFirefox = (): boolean => {
  return /firefox/i.test(navigator.userAgent);
};
