import React, { forwardRef } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

// On fusionne ReactDatePickerProps et React.RefAttributes
type CustomDatePickerProps = ReactDatePickerProps &
  React.RefAttributes<DatePicker>;

const CustomDatePicker = forwardRef<DatePicker, CustomDatePickerProps>(
  (props, ref) => <DatePicker {...props} ref={ref} />
) as React.ComponentType<CustomDatePickerProps>;

export default CustomDatePicker;
