import _ from "lodash";
import { useRef, useState } from "react";
import { Input, InputProps } from "./input-text";
import { CiEdit } from "react-icons/ci";
import { FiCheck } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { Base } from "@atoms/text";
import Select from "./input-select";

type InputEditProps = {
  options: { label: string; value: string }[];
  fieldType?: string;
  loading?: boolean;
  autoFocus?: "scan" | "keyboard" | boolean;
  onStartEdit?: () => void;
  onStopEdit?: () => void;
  onClickOption?: (e: any) => void;
  onValid?: (e: any) => void;
} & Omit<InputProps, "autoFocus">;

export const InputEdit = (props: InputEditProps) => {
  const [editMode, setEditMode] = useState(false);
  const [savedValue, setSavedValue] = useState<
    string | number | readonly string[] | undefined
  >();
  const value = typeof props.value === "string" ? props.value : "";
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    props.onChange &&
      props.onChange({
        target: {
          value: e.target.value,
        },
        currentTarget: {
          value: e.target.value,
        },
      } as any);
  };

  return (
    <div
      className={"relative w-full flex gap-4 items-center justify-between"}
      style={props.style}
    >
      {editMode ? (
        <>
          {(!props.fieldType || props.fieldType === "text") && (
            <Input
              size={props.size}
              inputClassName={props.inputClassName}
              inputRef={ref}
              autoFocus={
                props.autoFocus === "keyboard" || props.autoFocus === true
              }
              {..._.omit(props, "options", "loading", "autoFocus")}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onFocus={(e) => {
                e.stopPropagation();
                props.onFocus && props.onFocus(e as any);
              }}
              onBlur={(e) => {
                props.onBlur && props.onBlur(e as any);
              }}
              onChange={(e) => handleChange(e)}
            />
          )}
          {props.fieldType === "select" && props.options && (
            <Select
              onClick={(e) => e.stopPropagation()}
              className={props.className}
              value={(props.value as string) || ""}
              onChange={(e) =>
                handleChange({ target: { value: e.target.value } })
              }
              size={props.size}
              placeholder={props.placeholder}
              disabled={props.disabled}
              autoFocus={props.autoFocus ? true : undefined}
              shortcut={props.autoFocus ? ["down"] : undefined}
            >
              {props.options.map((el) => (
                <option value={el.value}>{el.label}</option>
              ))}
            </Select>
          )}
        </>
      ) : (
        <Base>
          {props.fieldType === "select" && props.options
            ? props.options.find((el) => el.value === value)?.label
            : value}
        </Base>
      )}

      {editMode ? (
        <div className="flex gap-4">
          <FiCheck
            className="w-5 h-5 text-green-500 cursor-pointer"
            onClick={async (e) => {
              e.stopPropagation();
              setEditMode(false);
              props.onValid && props.onValid(value);
              props.onStopEdit && props.onStopEdit();
            }}
          />
          <RxCross2
            className="w-5 h-5 text-red-500 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              props.onStopEdit && props.onStopEdit();
              setEditMode(false);
              handleChange({
                target: {
                  value: savedValue,
                },
              });
            }}
          />
        </div>
      ) : (
        <CiEdit
          className="w-5 h-5 cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.onStartEdit && props.onStartEdit();
            setEditMode(true);
            setSavedValue(value);
          }}
        />
      )}
    </div>
  );
};
