import { Page } from "@atoms/layout/page";
import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  ChartOptions,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  columnInfosTest,
  dataLibrairie,
  dataTest,
  stocksLivres,
  ventesGlobales,
  ventesParCategorie,
} from "./fakeData";
import { Frame } from "@atoms/layout/frame";
import { TableauBrun } from "@molecules/tableau-brun";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

const barOptions: ChartOptions<"bar"> = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Ventes Globales",
    },
    tooltip: {
      enabled: true,
      mode: "nearest",
      intersect: false,
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || "";
          if (label) {
            label += "";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        },
      },
    },
  },
};

const horizontalBarOption: ChartOptions<"bar"> = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Nombre de vente par livre",
    },
  },
};

const pieOptions: ChartOptions<"pie"> = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Ventes Globales",
    },
    datalabels: {
      formatter: (value, ctx) => {
        if (ctx.chart.data.labels)
          return `${ctx.chart.data.labels[ctx.dataIndex]} \n ${value}`;
      },
      backgroundColor: function (context: any) {
        return "grey";
      },
      anchor: "end",
      align: "start",
      offset: 32,
      color: "white",
      borderRadius: 6,
      textAlign: "center",
    },
  },
};

const barData = {
  labels: ventesGlobales.map((el) => el.date),
  datasets: [
    {
      label: "Nombre de transactions",
      data: ventesGlobales.map((el) => el.nombreTransactions),
      borderWidth: 1,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },

    {
      label: "CA",
      data: ventesGlobales.map((el) => el.chiffreAffaires),
      borderWidth: 1,
      backgroundColor: "rgba(54, 162, 235, 0.5)",
    },
  ],
};

const horizontalBarData = {
  labels: stocksLivres.map((el) => el.livre),
  datasets: [
    {
      label: "Nombre de ventes",
      data: stocksLivres.map((el) => el.quantite),
      borderWidth: 1,
      backgroundColor: stocksLivres.map(() => genererCouleurRGB()),
    },
  ],
};

const pieData = {
  labels: ventesParCategorie.map((el) => el.categorie),
  datasets: [
    {
      label: "CA",
      data: ventesParCategorie.map((el) => el.chiffreAffaires),
      borderWidth: 1,
      backgroundColor: ventesParCategorie.map(() => genererCouleurRGB()),
    },
  ],
};

export const chartData = {
  labels: dataLibrairie.map((el) => el.mois),
  datasets: [
    {
      type: "line" as const,
      label: "Dataset 1",
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      fill: false,
      data: dataLibrairie.map((el) => el.revenus),
    },
    {
      type: "bar" as const,
      label: "Dataset 2",
      backgroundColor: "rgb(75, 192, 192)",
      data: dataLibrairie.map((el) => el.livresVendus * 40),
      borderColor: "white",
      borderWidth: 2,
    },
  ],
};

export const DashboardPage = () => {
  return (
    <Page>
      <div className="flex flex-col gap-3">
        <div className="w-full h-1/2 flex gap-4">
          <Frame className="h-full w-1/2">
            <Bar data={barData} options={barOptions} />
          </Frame>
          <Frame className="h-full w-1/2">
            <Bar data={horizontalBarData} options={horizontalBarOption} />
          </Frame>
        </div>
        <div className="w-full h-1/2 flex gap-4">
          <Frame className="h-full w-1/2">
            <Pie data={pieData} options={pieOptions} />
          </Frame>
          <Frame className="h-full w-1/2">
            <Chart type="bar" data={chartData} />
          </Frame>
        </div>

        <TableauBrun columnInfos={columnInfosTest} rows={dataTest} />
      </div>
    </Page>
  );
};

function genererCouleurRGB() {
  const rouge = Math.floor(Math.random() * 256);
  const vert = Math.floor(Math.random() * 256);
  const bleu = Math.floor(Math.random() * 256);

  return `rgb(${rouge}, ${vert}, ${bleu}, 0.4)`;
}
