import { Page } from "@atoms/layout/page";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { Base, Info, Subtitle, Title } from "@atoms/text";
import { SupplierOrderLine } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { BookRender } from "@atoms/book";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { Button } from "@atoms/button/button";
import { useSetRecoilState } from "recoil";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { ArrowNarrowLeftIcon, PrinterIcon } from "@heroicons/react/outline";

export const SupplierOrderLinePage = () => {
  const [orderLines, setOrderLines] = useState<SupplierOrderLine[]>([]);
  const { modeGest, codeTypeProd, codeFourn, numCde } = useParams<{
    modeGest: string;
    codeTypeProd: string;
    codeFourn: string;
    numCde: string;
  }>();

  const setPdfModal = useSetRecoilState(PdfModal);
  const { selectedFournCde, getFournOrderLines, getSupplierDocument } =
    useSuppliers();
  const { current } = useShopLocations();

  const navigate = useNavigate();

  // --> Liste des colonnes à compléter
  const orderColumns: Column<SupplierOrderLine>[] = [
    {
      title: "Article",
      id: "",
      render: (p) => (
        <div
          className="flex flex-row items-center space-x-4 cursor-pointer"
          onClick={() =>
            navigate(
              ROUTES.Product.replace(/:ean/, p.ean13).replace(
                /:type/,
                p.tooltip.modeGest
              )
            )
          }
        >
          <div className="w-10 print:hidden">
            <BookRender src={p.imageURL} productType={p.tooltip.modeGest} />
          </div>
          <div className="flex flex-col">
            <Base
              className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
              data-tooltip={p.desig}
            >
              {p.desig}
            </Base>
            <Info>{p.ean13}</Info>
          </div>
        </div>
      ),
    },

    {
      title: "PU TTC",
      id: "prixBase",
      render: (p) => (
        <Base className="w-full text-end">
          {modeGest === "P"
            ? p.prixBase.toFixed(current?.devisNbDecimP)
            : p.prixBase.toFixed(current?.devisNbDecim)}
          {current?.devisSymb || "€"}
        </Base>
      ),
    },

    {
      title: "Opé.",
      id: "codeOpe",
      render: (p) => <Base>{p.codeOpe}</Base>,
    },
    // {
    //   title: "Panier",
    //   id: "panie",
    //   render: (p) => <Base>{p.panie}</Base>,
    // }, --> Demandera peut-être à être rajouté ??
    {
      title: "Quantité",
      id: "qte",
      render: (p) => <Base className="w-full text-end">{p.qte}</Base>,
    },
  ];

  return (
    <Page>
      <>
        <div className="w-full flex justify-between items-center">
          <div>
            <Title>Commande fournisseur</Title>
            <Subtitle>
              {codeFourn} - {numCde}
            </Subtitle>
          </div>
          <div className="flex gap-4">
            <Button
              theme="danger-outlined"
              onClick={() => {
                navigate(ROUTES.SupplierOrderPage);
              }}
              icon={(p) => <ArrowNarrowLeftIcon {...p} />}
            >
              Retour
            </Button>
            <Button
              theme="secondary"
              onClick={async () => {
                const res = await getSupplierDocument(
                  numCde || selectedFournCde.numCde,
                  "cde",
                  modeGest || "L",
                  codeTypeProd || selectedFournCde.numCde,
                  orderLines[0].codeLieuPourQui,
                  current?.centr || false
                );
                if (res) {
                  setPdfModal({
                    open: true,
                    pdfProps: {
                      blobFile: res,
                      iframeSize: {
                        width: "80%",
                        height: "90%",
                      },
                      iFrameClassname:
                        "w-full h-full flex justify-center items-center",
                    },
                  });
                }
              }}
              icon={(p) => <PrinterIcon {...p} />}
            >
              Imprimer bon de commande
            </Button>
          </div>
        </div>
        <TableGridSwitch
          className="w-full overflow-x-scroll"
          name="cdeFournLigne"
          data={orderLines}
          //onChangeMode={(v) => setGridMode({ activated: v })}
          //gridMode={gridMode.activated}
          tableColumns={orderColumns}
          onRequestData={async () => {
            console.log(codeTypeProd); //For linter
            const res = await getFournOrderLines(
              numCde || selectedFournCde.numCde
            );
            if (res) setOrderLines(res);
            else setOrderLines([]);
          }}
          renderGrid={() => <> </>}
          gridMode={false}
        />
      </>
    </Page>
  );
};
