import { Button } from "@atoms/button/button";
import { useScan } from "@atoms/input-scan/use-scan";
import { Input } from "@atoms/input/input-text";
import { InputWithSuggestions } from "@atoms/input/input-with-suggestion";
import { Frame } from "@atoms/layout/frame";
import { Page } from "@atoms/layout/page";
import { BaseBold, BaseBoldSmall, Info, Subtitle } from "@atoms/text";
import { useReception } from "@features/reception/state/use-reception";
import { ROUTES } from "@features/routes";
import { PlusIcon, ViewListIcon } from "@heroicons/react/outline";
import { Form } from "@molecules/form";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowsRotate } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useSetRecoilState } from "recoil";
import { CommandFournModalAtom } from "../receptions-modals/commande-fournisseur-modal";
import { UnknownArticlesModalAtom } from "../receptions-modals/unknown-articles-modal";
import { ReceptionLine, ReceptionUnit } from "@features/reception/types";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { PreviousScannedProduct } from "@features/reception/state/store";
import { NavigationBundle, NavigationContext } from "@views/navigation-bundle";
import { AffectationCmdClientModalAtom } from "../receptions-modals/affection-commande-client-modal";
import { CommonPagination } from "@features/types";
import Select from "@atoms/input/input-select";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { SupplierPackage } from "@features/supplier/types";
import { EclatKitModalAtom } from "../receptions-modals/eclat-kit-modal";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
//Page sûrement temporaire
//Pourra être refactorisée plus tard

export const ReceptionHeaderPage = () => {
  const {
    selectedReception,
    loading,
    createHeader,
    getReceipt,
    receiptScannedProduct,
    changeSelectedReception,
    getLines,
    getRelatedOrders,
    rappatrierLines,
    printLabels,
  } = useReception();
  const { functionsAuthorization } = useShopLocations();
  const { getFournBl, getFournBlPackages } = useSuppliers();
  const [inputValue, setInputValue] = useState("");
  const [droitRet, setDroitRet] = useState(false);
  const [allPackages, setAllPackages] = useState<SupplierPackage[]>([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const setEclatKitModal = useSetRecoilState(EclatKitModalAtom);

  //const [tauxList, setTauxList] = useState<Taux[]>([]);
  const setScannedProduct = useSetRecoilState(PreviousScannedProduct);
  const setCommandFournModal = useSetRecoilState(CommandFournModalAtom);
  const setUnknownArticlesModal = useSetRecoilState(UnknownArticlesModalAtom);
  const setAffectationModal = useSetRecoilState(AffectationCmdClientModalAtom);
  const { scanOn } = useScan();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { from } = useContext<NavigationBundle>(NavigationContext);

  useControlledEffect(() => {
    if (from && from.pathname.includes("ongoing")) navigate(ROUTES.Receptions);
  }, []);

  const isBlValid = () => {
    if (!selectedReception.numBL || selectedReception.numBL.trim() === "") {
      toast.error("Veuillez renseigner un numéro de BL !");
      const blInput = document.getElementById("bl-input");
      if (blInput) blInput.focus();
      setInputValue("");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      document.title = `Inférence - Creation Recep. ${selectedReception.codeFourn} - ${selectedReception.numRecep}`;
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedReception]);

  useControlledEffect(() => {
    if (selectedReception.numBL.trim.length === 0) {
      const blInput = document.getElementById("bl-input") as HTMLInputElement;
      if (blInput) blInput.focus();
    }
  }, []);

  useControlledEffect(() => {
    const updateRetDate = async () => {
      const res = await CommonApiClient.getCalculatedDroitRetour(
        selectedReception.codeTypeAchat,
        selectedReception.dateRecep
      );
      if (res)
        if (res.droitRet) setDroitRet(true);
        else setDroitRet(false);
      changeSelectedReception({
        ...(selectedReception as ReceptionUnit),
        dateMiniAvantRet: res.dateDebDroitRet,
        dateMaxiRet: res.dateFinDroitRet,
      });
    };

    updateRetDate();
  }, []);

  useControlledEffect(() => {
    setSelectedPackage(selectedReception.numColis);
  }, []);

  useControlledEffect(() => {
    const loadPackages = async () => {
      const blRes = await getFournBl(
        selectedReception.codeFourn,
        selectedReception.numBL
      );
      if (blRes.length > 0) setAllPackages(await getFournBlPackages(blRes[0]));
      else setAllPackages([]);
    };
    if (selectedReception.typeRecep === 0) loadPackages();
  }, []);

  return (
    <Page loading={loading}>
      <div className="flex flex-col gap-6">
        <div
          className="mx-auto w-full flex h-full w-full "
          style={{ maxWidth: 540 }}
        >
          <InputWithSuggestions
            id="ean-input"
            ean={true}
            options={[]}
            disabled={
              functionsAuthorization[
                `Recep-${selectedReception.codeTypeProd}`
              ] &&
              !functionsAuthorization[`Recep-${selectedReception.codeTypeProd}`]
                .insert
            }
            inputMode={scanOn ? "none" : undefined}
            inputClassName="to-focus !rounded-l-lg !rounded-r-none"
            autoFocus={true}
            placeholder="Scanner"
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
          />

          <Button
            className="shrink-0 !rounded-r-lg !rounded-l-none"
            shortcut={["enter"]}
            icon={(p) => <PlusIcon {...p} />}
            authorized={
              functionsAuthorization[
                `Recep-${selectedReception.codeTypeProd}`
              ] &&
              functionsAuthorization[`Recep-${selectedReception.codeTypeProd}`]
                .update
            }
            onClick={async () => {
              if (isBlValid()) {
                if (inputValue.trim().length > 0) {
                  //await createHeader({...selectedReception, modeGest : selectedReception.orig === "HCDE" ? res.});
                  const res = await receiptScannedProduct(inputValue, true);
                  if (res) {
                    const newReceipt = await getReceipt(selectedReception);
                    if (newReceipt) {
                      const relatedOrders = await getRelatedOrders(
                        selectedReception,
                        res.ean13,
                        res.linePk,
                        res.qteRecu
                      );
                      setScannedProduct({
                        ...res,
                        qteRecu: res.qteRecu ? res.qteRecu : 1,
                        nbrEtiq: res.qteRecu ? res.qteRecu : 1,
                        forceRelatedOrders: relatedOrders.length > 1,
                      });

                      changeSelectedReception(newReceipt);
                      navigate(
                        ROUTES.OngoingReception.replace(
                          /:numRecep/,
                          newReceipt.numRecep
                        ).replace(/:clotStep/, "0"),
                        {
                          state: {
                            scannedProduct: {
                              ...res,
                            },
                          },
                        }
                      );
                    } else {
                      toast.error(
                        "Erreur: impossible de vous rediriger directement sur la page de cette nouvelle réception."
                      );
                      navigate(ROUTES.Receptions);
                    }
                  }
                } else
                  toast.error("Veuillez saisir un EAN valide pour continuer.");
                const eanInput = document.getElementById("ean-input");
                if (eanInput) eanInput.focus();
              }
            }}
          />
        </div>
        <div className="flex gap-4 mt-4 justify-between">
          {selectedReception.typeRecep !== 1 && (
            <BaseBold className="flex flex-col justify-center">
              Création d'une récéption sur
              {selectedReception.typeRecep === 0 && !selectedReception.numColis
                ? ` AVEX: ${selectedReception.numBL} `
                : selectedReception.typeRecep === 0 &&
                  selectedReception.numColis
                ? ` colis: ${selectedReception.numColis} ${
                    selectedReception.idColis !== ""
                      ? `- ${selectedReception.idColis}`
                      : ""
                  }
                  `
                : selectedReception.typeRecep === 2
                ? ` commande fournisseur: ${selectedReception.numCde}`
                : selectedReception.typeRecep === 3
                ? ""
                : ""}
            </BaseBold>
          )}

          {selectedReception.listReceiptCdeFourn &&
            !(
              selectedReception.listReceiptCdeFourn.length === 1 &&
              selectedReception.listReceiptCdeFourn.find(
                (el) => el.numCde === null
              )
            ) && (
              <Button
                className="shrink-0"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  setCommandFournModal({
                    open: true,
                    listCde: selectedReception.listReceiptCdeFourn,
                  });
                }}
                theme="secondary"
                icon={({ className }) => <ViewListIcon className={className} />}
              >
                {selectedReception.listReceiptCdeFourn.length > 1
                  ? `Commande fournisseurs associées (${selectedReception.listReceiptCdeFourn.length})`
                  : `Commande ${selectedReception.listReceiptCdeFourn[0].numCde} associée`}
              </Button>
            )}
          {selectedReception.orig !== "HCDE" && (
            <Button
              size="sm"
              theme="secondary"
              disabled={loading}
              authorized={
                functionsAuthorization[
                  `Recep-${selectedReception.codeTypeProd}`
                ] &&
                functionsAuthorization[
                  `Recep-${selectedReception.codeTypeProd}`
                ].update
              }
              icon={({ className }) => <FaArrowsRotate className={className} />}
              onClick={async () => {
                if (isBlValid()) {
                  const resHeader = await createHeader(selectedReception);
                  if (!resHeader) {
                    toast.error("Impossible de crée l'en tête de réception.");
                    //navigate(ROUTES.Receptions);
                    return;
                  }
                  const resRappat = await rappatrierLines(selectedReception);
                  if (!resRappat) {
                    toast.error("Impossible de rappatrier les lignes.");
                    return;
                  }

                  const newReceipt = await getReceipt(selectedReception);
                  const allLines = (await getLines(
                    newReceipt,
                    {
                      orderDir: undefined,
                      pageNumber: 0,
                      pageSize: 9999,
                    },
                    true
                  )) as {
                    pagination: CommonPagination;
                    items: ReceptionLine[];
                  };
                  const kits = allLines.items.filter((el) => el.kit);
                  if (resRappat.length !== 0) {
                    setUnknownArticlesModal({
                      open: true,
                      unknownArticles: resRappat,
                      kits: kits,
                    });
                  }
                  if (resRappat.length === 0 && kits.length > 0) {
                    setEclatKitModal({
                      open: true,
                      receipt: newReceipt,
                      products: kits,
                      closeOnValid: false,
                    });
                  }
                  if (
                    newReceipt.recepAffecCdeCli === "2" ||
                    newReceipt.recepAffecCdeCli === "3"
                  ) {
                    const filteredLines = allLines.items.filter(
                      (l) => l.qteCdeCli > 0
                    );
                    if (filteredLines.length > 0) {
                      const orders = await getRelatedOrders(
                        newReceipt,
                        filteredLines[0].ean13,
                        filteredLines[0].linePk,
                        filteredLines[0].qteRecu,
                        true
                      );
                      setAffectationModal({
                        open: true,
                        receipt: newReceipt,
                        item: filteredLines[0],
                        orders: orders,
                        total: 0,
                        automaticMode: true,
                        itemList: filteredLines,
                        autoPrint: true,
                      });
                    } else {
                      if (newReceipt.etiqCdeCli !== 2) {
                        await printLabels(
                          newReceipt,
                          undefined,
                          undefined,
                          undefined,
                          true
                        );
                      }
                    }
                  }
                  if (
                    newReceipt.etiqCdeCli !== 2 &&
                    newReceipt.recepAffecCdeCli !== "2" &&
                    newReceipt.recepAffecCdeCli !== "3"
                  ) {
                    await printLabels(
                      newReceipt,
                      undefined,
                      undefined,
                      undefined,
                      true
                    );
                  }

                  if (newReceipt) {
                    navigate(
                      ROUTES.OngoingReception.replace(
                        /:numRecep/,
                        newReceipt.numRecep
                      ).replace(/:clotStep/, "0"),
                      {
                        state: {
                          kit: kits,
                        },
                      }
                    );
                  } else {
                    toast.error(
                      "Erreur: impossible de vous rediriger directement sur la page de cette nouvelle réception."
                    );
                    navigate(ROUTES.Receptions);
                  }
                }
              }}
            >
              Rapatrier les lignes
            </Button>
          )}
        </div>
        {selectedReception.typeRecep === 0 && allPackages.length > 0 && (
          <div key={selectedPackage} className="flex gap-2">
            <BaseBoldSmall className="flex flex-col justify-center">
              Choix du colis
            </BaseBoldSmall>
            <Select
              className="max-w-64"
              size="sm"
              value={selectedPackage}
              onChange={(e) => {
                setSelectedPackage(e.target.value);
                if (e.target.value === "-")
                  changeSelectedReception({
                    ...selectedReception,
                    numColis: "",
                    orig: "",
                    bord: "",
                  });
                else
                  changeSelectedReception({
                    ...selectedReception,
                    numColis: e.target.value,
                    idColis:
                      allPackages.find((l) => l.numColis === e.target.value)
                        ?.idColis || "",
                    bord: e.target.value,
                    orig: "COLIS",
                  });
              }}
            >
              <option value="-">-</option>$
              {allPackages.map((c) => (
                <option
                  //value={c.idColis}
                  value={c.numColis}
                >{`Numéro colis: ${c.numColis} - ${c.idColis}`}</option>
              ))}
            </Select>
          </div>
        )}
        <Frame className="mb-2">
          <div className="flex flex-row gap-3 items-center mb-4">
            <Subtitle className="">{`${selectedReception.codeFourn}`}</Subtitle>
            {selectedReception.numRecep && (
              <Subtitle>
                {t("Reception") + `${selectedReception.numRecep}`}
              </Subtitle>
            )}

            <div className="flex gap-3 text-center items-center">
              {selectedReception.typeRecep !== 0 ? (
                <>
                  <Subtitle>- BL </Subtitle>
                  <Input
                    id="bl-input"
                    size="sm"
                    className="max-w-48"
                    value={selectedReception.numBL}
                    onChange={(e) =>
                      changeSelectedReception({
                        ...selectedReception,
                        numBL: e.target.value,
                      })
                    }
                  />
                </>
              ) : (
                <Subtitle>{`- BL ${selectedReception.numBL}`} </Subtitle>
              )}

              {!selectedReception.numColis && (
                <>
                  <Subtitle>- Bordereau </Subtitle>
                  <Input
                    id="bl-input"
                    size="sm"
                    className="max-w-48"
                    value={selectedReception.bord}
                    onChange={(e) =>
                      changeSelectedReception({
                        ...selectedReception,
                        bord: e.target.value,
                      })
                    }
                  />
                </>
              )}
            </div>

            {selectedReception.numColis && (
              <>
                {selectedReception.orig !== "COLIS" && (
                  <Subtitle className="">
                    {`- Bordereau ${selectedReception.bord} `}
                  </Subtitle>
                )}
                {selectedReception.orig === "COLIS" && (
                  <Subtitle className="">
                    {`- Bordereau ${selectedReception.bord} `}
                  </Subtitle>
                )}
              </>
            )}
          </div>

          <Form
            readonly={false}
            onChange={async (newValue) => {
              if (newValue.codeTypeAchat !== selectedReception.codeTypeAchat) {
                const res = await CommonApiClient.getCalculatedDroitRetour(
                  newValue.codeTypeAchat,
                  selectedReception.dateRecep
                );
                if (res.droitRet) setDroitRet(true);
                else setDroitRet(false);
                changeSelectedReception({
                  ...(newValue as ReceptionUnit),
                  dateMiniAvantRet: res.dateDebDroitRet,
                  dateMaxiRet: res.dateFinDroitRet,
                });
              } else
                changeSelectedReception({ ...(newValue as ReceptionUnit) });
            }}
            value={selectedReception}
            fields={[
              {
                key: "codeTypeTaux",
                label: t("reception.tauxImplicite"),
                type: "text",
                alwaysVisible: true,
                // options: tauxList.map((el) => {
                //   return { label: el.lib, value: el.code };
                // }),
              },
              {
                key: "codeTypeAchat",
                label: t("reception.typeAchat"),
                type: "select",
                alwaysVisible: true,
                options: [
                  {
                    label: "Achat ferme",
                    value: "0",
                  },
                  {
                    label: "Office",
                    value: "1",
                  },
                  {
                    label: "Dépôt permanent",
                    value: "2",
                  },
                  {
                    label: "Dépôt occasionnel",
                    value: "3",
                  },
                  {
                    label: "Droit de retour",
                    value: "4",
                  },
                ],
              },
              {
                key: "recepAffecCdeCli",
                label: "Affecter commandes clients",
                type: "select",
                alwaysVisible: true,
                options: [
                  {
                    label: "Non",
                    value: "0",
                  },
                  { label: "Oui", value: "1" },

                  {
                    label: "Oui par défaut",
                    value: "2",
                  },
                  {
                    label: "Non par défaut",
                    value: "3",
                  },

                  //N'existe apparament dans Inférence
                  // {
                  //   label: "Oui/PS",
                  //   value: "",
                  // },
                ],
              },
              {
                key: "modeAffecCdeCli",
                label: t("reception.Mode affectation"),
                type: "select",
                alwaysVisible: true,
                options: [
                  {
                    label: "Par ancienneté cde cli",
                    value: "0",
                  },
                  {
                    label: "Priorité aux cde cli associées",
                    value: "PRIO",
                  },
                  {
                    label: "Exclusivement cde cli associées",
                    value: "EXCL",
                  },
                ],
              },
              {
                key: "dateMiniAvantRet",
                label: t("Retour des le"),
                type: "date",
                hidden: !droitRet,
              },
              {
                key: "dateMaxiRet",
                label: t("retourJusquau"),
                type: "date",
                alwaysVisible: true,
                hidden: !droitRet,
              },

              {
                key: "etiqCdeCli",
                label: "Étiqueter les commandes",
                type: "select",
                options: [
                  { label: "Étiquetage habituel", value: "0" },
                  {
                    label: "Étiqueter toutes les commandes client",
                    value: "1",
                  },
                  {
                    label: "Ne pas étiqueter les commandes client",
                    value: "2",
                  },
                ],
                alwaysVisible: true,
              },
            ]}
          />
          <div className="flex flex-col mt-4 gap-1">
            <Info>Commentaire interne</Info>
            <Input
              multiline={true}
              value={selectedReception.comm}
              onChange={(e) =>
                changeSelectedReception({
                  ...selectedReception,
                  comm: e.target.value,
                })
              }
            />
          </div>
        </Frame>
      </div>
    </Page>
  );
};
