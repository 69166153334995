import React, { useEffect, useRef, useState } from "react";

export const DynamicGrid = (props: {
  children: React.ReactNode;
  className?: string;
  minColumns?: number;
  nbColumns?: number; //Nb fixe de colonnes
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState(0);

  useEffect(() => {
    const updateColumns = () => {
      if (!containerRef.current) return;
      const width = containerRef.current.offsetWidth;

      if (props.nbColumns) {
        setColumns(props.nbColumns);
      } else {
        if (width >= 1536) setColumns(8);
        else if (width >= 1200) setColumns(6);
        else if (width >= 800) setColumns(4);
        else if (width >= 600) setColumns(3);
        else if (width >= 400) setColumns(2);
        else setColumns(props.minColumns || 1);
      }
    };

    updateColumns();

    window.addEventListener("resize", updateColumns);
    return () => {
      window.removeEventListener("resize", updateColumns);
    };
  }, [props.minColumns, props.nbColumns]);

  return (
    <div
      ref={containerRef}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
      className={props.className}
    >
      {!!columns && props.children}
    </div>
  );
};
