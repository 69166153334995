export const getRouteProduct = (productType: string) => {
  switch (productType) {
    case "D":
      return "record";
    case "L":
      return "book";
    case "N":
      return "ebook";
    case "S":
      return "PRES";
    case "P":
      return "paper";
  }
};
