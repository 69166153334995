import environment from "@config/environment";
import { AuthJWT } from "../general/auth/jwt";
import toast from "react-hot-toast";
import { updateLastRequestTime } from "./utils-global-state";

export const fetchServer = async (
  url: string,
  options: RequestInit & { reloadOn401?: boolean; reloadOn403?: boolean } = {
    reloadOn401: false,
    reloadOn403: false,
  },
  forbidRefresh?: boolean
): Promise<Response> => {
  if (!forbidRefresh) {
    updateLastRequestTime();
    refreshLastRequestTime();
  }

  let token = AuthJWT.token;
  let codeLieu = AuthJWT.codeLieu;
  if (!token) token = localStorage.getItem("user.access_token");
  if (!codeLieu) codeLieu = localStorage.getItem("user.code_lieu");

  // Automatically include the token and set the content type
  options.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CodeLieu": codeLieu ? codeLieu.replaceAll('"', "") : "",
    ...(token ? { Authorization: `Bearer ${token.replaceAll('"', "")}` } : {}),
    ...options.headers,
  };
  // options.headers = {
  //   "Content-Type": "application/json",
  //   Accept: "application/json",
  //   "X-CodeLieu": AuthJWT.codeLieu || "",
  //   ...(token ? { Authorization: `Bearer ${token}` } : {}),
  //   ...options.headers,
  // };
  //Automatically include the codeLieu
  // if (options.body && AuthJWT.codeLieu && typeof options.body === "string") {
  //   try {
  //     const obj = JSON.parse(options.body);
  //     obj.codeLieu = AuthJWT.codeLieu;
  //     options.body = JSON.stringify(obj);
  //   } catch (e) {}
  // }
  const data = await fetch(environment.server + url, options);

  if (data.status === 500) {
    const res = await data.json();
    const refreshToken = localStorage.getItem("user.refresh_token");
    if (refreshToken && refreshToken !== "null")
      toast.error("Une erreur interne est survenue: " + res.detail);
  }
  if (data.status === 401 && options.reloadOn401 !== false) {
    document.location.reload();
  }
  if (data.status === 403 && options.reloadOn403 !== false) {
    const res = await data.json();
    toast.error(res.detail);
    setTimeout(() => {
      document.location.reload();
    }, 3000);
  }

  return data;
};

const refreshLastRequestTime = (): void => {
  const event = new CustomEvent<Date>("lastRequestTimeUpdated", {
    detail: new Date(),
  });
  window.dispatchEvent(event);
};
