import { fetchServer } from "@features/utils/fetch-server";
import { DroitRetour, ProductAvailability, Taxe, TypeAchat } from "../types";
import toast from "react-hot-toast";
import { TableInfos } from "@molecules/table/create-columns";
import { Taux } from "@features/reception/types";

export class CommonApiClient {
  static getCountries = async (clientId: string) => {
    const data = await fetchServer(`/country`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getRegulStockMotifs = async () => {
    const data = await fetchServer(`/stockregulationreason`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getOrderStatusList = async () => {
    const data = await fetchServer(`/order/line/statuslist`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getSupplierOrderStatusList = async () => {
    const data = await fetchServer(`/supplier/order/statuslist`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getTaxes = async () => {
    const data = await fetchServer(`/tax`, {
      method: "GET",
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as Taxe[];
  };

  static getTaux = async (codeTypeProd?: string) => {
    const fetchUrl = codeTypeProd
      ? `/ratetype?codeTypeProd=${codeTypeProd}`
      : `/ratetype`;
    const data = await fetchServer(fetchUrl);
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as Taux[];
  };

  static getGroupeLieu = async () => {
    const data = await fetchServer("/grpelieu");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: any) => {
      return { label: el.codeGroupe, value: el.raisSocial };
    });
  };

  static getNotations = async () => {
    const data = await fetchServer("/notations");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: any) => {
      return { label: el.lib, value: el.code };
    });
  };

  static getTypeAchat = async () => {
    const data = await fetchServer("/typeachat");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as TypeAchat[];
  };

  static getTransmissions = async () => {
    const data = await fetchServer("/edi/transmissions");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: any) => {
      return { label: el.modeTransCde, value: el.modeTransCde };
    });
  };

  static getEdiStatus = async () => {
    const data = await fetchServer("/edi/statuslist");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: any) => {
      return { label: el.libelle, value: el.code };
    });
  };

  static getForms = async () => {
    const data = await fetchServer("/edi/forms");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: any) => {
      return { label: el.libForm, value: el.codeForm };
    });
  };

  static getServEdis = async () => {
    const data = await fetchServer("/edi/servers");
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.map((el: any) => {
      return { label: el.nomServEdi, value: el.nomServEdi };
    });
  };

  static getAvailibility = async (codeTypeProd?: string) => {
    const data = await fetchServer(
      "/availability" +
        `${codeTypeProd ? "?codeTypeProd=" + codeTypeProd : ""}`,
      {
        method: "GET",
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ProductAvailability[];
  };

  static getCalculatedDroitRetour = async (
    codeTypeAchat: string,
    dateRecep: string
  ) => {
    const data = await fetchServer("/returnrightscalculation", {
      method: "POST",
      body: JSON.stringify({
        codeTypeAchat: codeTypeAchat,
        dateRecep: dateRecep,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as DroitRetour;
  };

  static getAmountCalculationByTaxe = async (
    orig: string,
    code: string,
    ht: number,
    ttc: number
  ) => {
    const data = await fetchServer("/amountcalculationbytaxe", {
      method: "POST",
      body: JSON.stringify({
        orig: orig,
        code: code,
        ht: +ht,
        ttc: ttc,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      toast.error("Calcul des frais impossibles. Erreur: " + result.title);
      throw new Error(result.title);
    }
    return result as { ht: number; ttc: number };
  };

  static getTablePres = async (userId: string, presId: string) => {
    const data = await fetchServer(
      `/user/table?user_id=${userId}&table_id=${presId}`,
      {
        method: "GET",
      }
    );
    const result = await data.json();
    if (data.status !== 200) {
      toast.error(
        "Impossible de récupérer les informations du tableau. Erreur: " +
          result.title
      );
      throw new Error(result.title);
    }
    return result as TableInfos;
  };

  static updateTablePres = async (
    userId: string,
    presId: string,
    tableInfos: TableInfos
  ) => {
    const tableColumns = { columns: tableInfos.tableColumns };

    const data = await fetchServer(`/user/table`, {
      method: "POST",
      body: JSON.stringify({
        userId: userId,
        tableId: presId,
        tableColumns: JSON.stringify(tableColumns),
      }),
    });

    if (data.status !== 204) {
      const result = await data.json();
      toast.error(
        "Impossible de modifier la présentation de ce tableau. Erreur: " +
          result.title
      );
      throw new Error(result.title);
    }
    return true;
  };
}
