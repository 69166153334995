export const ventesGlobales = [
  { date: "2024-02-14", chiffreAffaires: 995, nombreTransactions: 23 },
  { date: "2024-02-15", chiffreAffaires: 1591, nombreTransactions: 29 },
  { date: "2024-02-16", chiffreAffaires: 908, nombreTransactions: 41 },
  { date: "2024-02-17", chiffreAffaires: 1387, nombreTransactions: 20 },
  { date: "2024-02-18", chiffreAffaires: 1345, nombreTransactions: 10 },
  { date: "2024-02-19", chiffreAffaires: 938, nombreTransactions: 50 },
  { date: "2024-02-20", chiffreAffaires: 797, nombreTransactions: 31 },
  { date: "2024-02-21", chiffreAffaires: 915, nombreTransactions: 21 },
  { date: "2024-02-22", chiffreAffaires: 794, nombreTransactions: 27 },
  { date: "2024-02-23", chiffreAffaires: 927, nombreTransactions: 44 },
  { date: "2024-02-24", chiffreAffaires: 944, nombreTransactions: 15 },
  { date: "2024-02-25", chiffreAffaires: 716, nombreTransactions: 33 },
  { date: "2024-02-26", chiffreAffaires: 1752, nombreTransactions: 38 },
  { date: "2024-02-27", chiffreAffaires: 510, nombreTransactions: 29 },
  { date: "2024-02-28", chiffreAffaires: 1214, nombreTransactions: 47 },
];

export const ventesParCategorie = [
  { categorie: "Romans", chiffreAffaires: 3139 },
  { categorie: "Sciences", chiffreAffaires: 7070 },
  { categorie: "Jeunesse", chiffreAffaires: 6579 },
  { categorie: "BD", chiffreAffaires: 2173 },
  { categorie: "Histoire", chiffreAffaires: 8001 },
  { categorie: "Cuisine", chiffreAffaires: 2005 },
  { categorie: "Voyage", chiffreAffaires: 6396 },
  { categorie: "Science-Fiction", chiffreAffaires: 5022 },
  { categorie: "Biographies", chiffreAffaires: 1053 },
  { categorie: "Économie", chiffreAffaires: 4710 },
  { categorie: "Santé", chiffreAffaires: 4391 },
  { categorie: "Musique", chiffreAffaires: 2727 },
];

export const stocksLivres = [
  { livre: "Livre A", quantite: 50 },
  { livre: "Livre B", quantite: 74 },
  { livre: "Livre C", quantite: 89 },
  { livre: "Livre D", quantite: 89 },
  { livre: "Livre E", quantite: 92 },
  { livre: "Livre F", quantite: 27 },
  { livre: "Livre G", quantite: 36 },
  { livre: "Livre H", quantite: 71 },
  { livre: "Livre I", quantite: 98 },
  { livre: "Livre J", quantite: 88 },
  { livre: "Livre K", quantite: 95 },
  { livre: "Livre L", quantite: 5 },
  { livre: "Livre M", quantite: 6 },
  { livre: "Livre N", quantite: 54 },
  { livre: "Livre O", quantite: 92 },
];

export const dataLibrairie = [
  { mois: "01/2023", livresVendus: 120, revenus: 3100 },
  { mois: "02/2023", livresVendus: 110, revenus: 2800 },
  { mois: "03/2023", livresVendus: 140, revenus: 800 },
  { mois: "04/2023", livresVendus: 130, revenus: 900 },
  { mois: "05/2023", livresVendus: 150, revenus: 3200 },
  { mois: "06/2023", livresVendus: 160, revenus: 2000 },
  { mois: "07/2023", livresVendus: 170, revenus: 3500 },
  { mois: "08/2023", livresVendus: 180, revenus: 2100 },
  { mois: "09/2023", livresVendus: 150, revenus: 3200 },
  { mois: "10/2023", livresVendus: 140, revenus: 3600 },
  { mois: "11/2023", livresVendus: 130, revenus: 3300 },
  { mois: "12/2023", livresVendus: 150, revenus: 2800 },
];

export const columnInfosTest = [
  { title: "Nom", type: "string", key: "name" },
  { title: "Âge", type: "numeric", key: "age" },
  { title: "Pays", type: "string", key: "country", editable: true },
];

export const dataTest = [
  { name: "Alice", age: 25, country: "France" },
  { name: "Bob", age: 30, country: "Canada" },
  { name: "Charlie", age: 35, country: "États-Unis" },
  { name: "Diana", age: 28, country: "Royaume-Uni" },
  { name: "Ethan", age: 40, country: "Allemagne" },
  { name: "Fiona", age: 22, country: "Espagne" },
  { name: "George", age: 33, country: "Italie" },
  { name: "Hannah", age: 27, country: "Pays-Bas" },
  { name: "Ian", age: 31, country: "Australie" },
  { name: "Julia", age: 29, country: "Brésil" },
];
