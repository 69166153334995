import { Base } from "@atoms/text";
import { formatTime } from "@features/utils/format/dates";
import { Column } from "@molecules/table/table";

const LIVR_REAS_COLUMNS = [
  {
    title: "Rayon",
    id: "code_rayon",
    searchIds: ["codeRayon"],
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Vendeur",
    id: "logi",
    searchIds: ["logi"],
    orderable: true,
    render: (p) => <Base>{p.logi}</Base>,
  },
  {
    title: "Fournisseur",
    id: "code_fourn_habit",
    searchIds: ["codeFournHabit"],
    orderable: true,
    render: (p) => <Base>{p.codeFournHabit}</Base>, // À vérifier
  },
  {
    title: "Mouvement",
    id: "lib_type_mvt",
    searchIds: ["libTypeMvt"],
    orderable: true,
    render: (p) => <Base>{p.libTypeMvt}</Base>,
  },
  {
    title: "Stock",
    orderable: true,
    id: "qte_stock",
    searchIds: ["qteStock"],
    render: (p) => <Base className="w-full text-end">{p.qteStock}</Base>,
  },
  {
    title: "Auteur",
    id: "auteu",
    searchIds: ["auteu"],
    orderable: true,
    render: (p) => <Base>{p.auteur}</Base>,
  },
  {
    title: "Auteur-Compositeur",
    id: "auteurCompositeur",
    searchIds: ["auteurCompositeur"],
    orderable: true,
    render: (p) => <Base>{p.auteurCompositeur}</Base>,
  },
  {
    title: "Éditeur",
    id: "code_edit",
    searchIds: ["codeEdit"],
    orderable: true,
    render: (p) => <Base>{p.codeEdit}</Base>,
  },
  {
    title: "Fabricant",
    id: "code_fab",
    searchIds: ["codeFab"],
    orderable: true,
    render: (p) => <Base>{p.codeFab}</Base>,
  },

  {
    title: "Marque",
    id: "code_marque",
    searchIds: ["codeMarque"],
    orderable: true,
    render: (p) => <Base>{p.codeMarque}</Base>, // À vérifier
  },
  {
    title: "Promo",
    id: "prod_lieu_canal_vente_code_promo",
    searchIds: ["codePromo"],
    orderable: true,
    render: (p) => <Base>{p.codePromo}</Base>, // À vérifier
  },
  {
    title: "Departement",
    id: "code_dept",
    searchIds: ["codeDept"],
    orderable: true,
    render: (p) => <Base>{p.codeDept}</Base>,
  },
  {
    title: "Marge Nette",
    id: "marge_nette_nette",
    searchIds: ["margeNetteNette"],
    orderable: true,
    render: (p) => <Base className="w-full text-end">{p.margeNetteNette}</Base>,
  },
  {
    title: "Couv.",
    id: "delai_stock",
    orderable: true, // Mettre id
    searchIds: ["delaiStock"],
    render: (p) => <Base className="w-full text-end">{p.delaiStock}</Base>,
  },
  {
    title: "Vente",
    id: "vente", //Vérifier sauvegarde modif tab
    searchIds: ["vente"],
    orderable: true,
    render: (p) => <Base className="w-full text-end">{p.vente}</Base>,
  },
  {
    title: "Vente jour",
    id: "qte_vente_jour",
    searchIds: ["qteVenteJour"],
    orderable: true,
    render: (p) => <Base>{p.qteVenteJour}</Base>,
  },
  {
    title: "Prix Vente",
    id: "prix_vente_HT",
    searchIds: ["prixVenteHT"],
    orderable: true,
    render: (p) => <Base>{p.prixVenteHT}</Base>,
  },
  {
    title: "Première entrée",
    id: "date_prem_entre",
    searchIds: ["datePremEntre"],
    type: "date",
    orderable: true,
    render: (p) => (
      <Base>
        {formatTime(p.datePremEntre as string, "fr-FR", {
          keepTime:
            new Date(p.datePremEntre as string).getSeconds() === 0
              ? false
              : true,
          keepSeconds: false,
          keepDate: true,
        })}
      </Base>
    ),
  },
  {
    title: "Éditeur",
    id: "code_edit",
    searchIds: ["codeEdit"],
    orderable: true,
    render: (p) => <Base>{p.codeEdit}</Base>,
  },
  {
    title: "Diffuseur",
    id: "code_diffu",
    searchIds: ["codeDiffu"],
    orderable: true,
    render: (p) => <Base>{p.codeDiffu}</Base>,
  },
  {
    title: "CodeColl", // Titre et clé à vérifier
    id: "codeColl",
    searchIds: ["codeColl"],
    orderable: true,
    render: (p) => <Base>{p.codeColl}</Base>,
  },
  {
    title: "État",
    id: "code_etat",
    searchIds: ["codeEtat"],
    orderable: true,
    render: (p) => <Base>{p.codeEtat}</Base>,
  },
  {
    title: "Support",
    id: "lib_supp",
    searchIds: ["support"],
    orderable: true,
    render: (p) => <Base>{p.support}</Base>,
  },
  {
    title: "Genre",
    id: "lib_genre",
    searchIds: ["genre"],
    orderable: true,
    render: (p) => <Base>{p.genre}</Base>,
  },
] as Column<any>[];

export const getReassortTabColumns = (modeGestion: string) => {
  switch (modeGestion) {
    case "L":
      return LIVR_REAS_COLUMNS;
    case "D":
      return LIVR_REAS_COLUMNS;
    case "P":
      return [
        ...LIVR_REAS_COLUMNS,
        {
          title: "Famille",
          id: "lib_famil",
          searchIds: ["famille"],
          orderable: true,
          render: (p: any) => <Base>{p.famille}</Base>,
        },
        {
          title: "Sous-famille",
          id: "lib_sous_famil",
          searchIds: ["sousFamille"],
          orderable: true,
          render: (p: any) => <Base>{p.sousFamille}</Base>,
        },
      ];
    default:
      return LIVR_REAS_COLUMNS;
  }
};
