import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { BaseBold, InfoSmall, InfoSmallBold } from "@atoms/text";
import { useBasket } from "@features/products/state/use-basket";
import { useBaskets } from "@features/products/state/use-baskets";
import { ProductType, ProductTypeShort } from "@features/products/types";
import { nanoid } from "nanoid";
import {
  BookOpenIcon,
  ClockIcon,
  CubeIcon,
  NewspaperIcon,
  PlusCircleIcon,
  ReplyIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  StopIcon,
  TagIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { ImCodepen } from "react-icons/im";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { ShortcutMapping } from "@features/settings/shortcuts/mapping";
import { useEffect, useState } from "react";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import Ribbon from "@atoms/ribbon";
import { FaBuildingWheat } from "react-icons/fa6";
import { useSetRecoilState } from "recoil";
import { LieuStockAtom } from "@views/client/products/product/lieu-stock-modal";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { flushGlobalEffects } from "@features/utils/hooks/use-global-effect";
import {
  formatAmount,
  getCommonProductTooltip,
} from "@features/utils/format/strings";
import { useProducts } from "@features/products/state/use-products";
import { RegulStockModalAtom } from "@views/client/products/product/regul-stock-modal";

//À vérifier/changer
export const getProductTypeIcon = (
  productType: ProductTypeShort["codeTypeProd"]
) => {
  switch (productType) {
    case "LIVR":
      return <BookOpenIcon className="h-4 w-4" />;
    case "PAPE":
      return <CubeIcon className="h-4 w-4" />;
    case "PRES":
      return <NewspaperIcon className="h-4 w-4" />;
    case "DISQ":
      return <StopIcon className="h-4 w-4" />;
    default:
      return <CubeIcon className="h-4 w-4" />;
  }
};

export const ProductActions = ({
  product,
  shortcuts,
  size,
}: {
  product: ProductTypeShort | ProductType;
  shortcuts?: boolean;
  size?: "sm" | "lg" | "md" | "xmd";
}) => {
  const navigate = useNavigate();
  const { referGestion } = useParams<{ referGestion: string }>();
  const { current, addBasket, setCurrent } = useBaskets();
  const { products, add, remove, putAside } = useBasket(current);
  const { loading, etiqProducts } = useProducts();
  const { functionsAuthorization } = useShopLocations();
  //console.log(functionsAuthorization);
  //const { selectedPrepHeader } = useSuppliers();
  //const { current: currentShop } = useShopLocations(); // --> UNCOMENT THIS PART WHEN READY
  const setLieuStockModal = useSetRecoilState(LieuStockAtom);
  const setRegulStockModal = useSetRecoilState(RegulStockModalAtom);

  //const setSearchProductModal = useSetRecoilState(SearchProductModalAtom);

  const location = useLocation();
  const [cdeCliEnabled, setCdeClitEnabled] = useState(false);
  const basketSize = products.find((e) => e.product.id === product.id)?.count;
  const isAside = products.find((e) => e.product.id === product.id)?.aside;
  size = size || "md";

  const urlPattern: RegExp =
    /^\/products\/([a-zA-Z0-9-_]+)\/([a-zA-Z0-9-_]+)\/(true|false)$/;

  useEffect(() => {
    setCdeClitEnabled(
      Object.keys(functionsAuthorization)
        .filter((k) => k.includes("Cde"))
        .map((k) => functionsAuthorization[k].insert)
        .some((v) => v === true)
    );
  }, [functionsAuthorization]);

  return (
    <>
      {(basketSize || 0) > 0 && window.top === window.self && (
        <Button
          className="shrink-0 z-10"
          size={size}
          onClick={(e) => {
            e.stopPropagation();
            putAside(product);
          }}
          theme={!isAside ? "secondary" : "primary"}
          data-tooltip={!isAside ? "Mettre de côté" : "Remettre dans le panier"}
          data-tooltip-id="my-tooltip-inline"
          data-click="Disponible prochainement !"
          icon={({ className }) =>
            !isAside ? (
              <ClockIcon className={className} />
            ) : (
              <PlusCircleIcon className={className} />
            )
          }
          shortcut={
            shortcuts
              ? [ShortcutMapping.products.start_supplier_return]
              : undefined
          }
        />
      )}
      <Button
        disabled={
          (urlPattern.test(location.pathname) && referGestion === "false") ||
          (!urlPattern.test(location.pathname) && !product.referGestion)
        }
        className="shrink-0"
        size={size}
        onClick={async (e) => {
          e.stopPropagation();
          const res = await ProductsApiClient.getLieuStock(product.id);
          setLieuStockModal({
            open: true,
            product: product,
            lieuStockList: res,
          });
        }}
        theme="secondary"
        data-tooltip="Stocks des lieux"
        data-tooltip-id="my-tooltip-inline"
        icon={({ className }) => <FaBuildingWheat className={className} />}
      />

      {/* {currentShop?.multiLieu && (
        <Button
          disabled={
            (urlPattern.test(location.pathname) && referGestion === "false") ||
            (!urlPattern.test(location.pathname) && !product.referGestion)
          }
          className="shrink-0"
          size={size}
          onClick={async (e) => {
            e.stopPropagation();
            const res = await ProductsApiClient.getLieuStock(product.id);
            setLieuStockModal({
              open: true,
              product: product,
              lieuStockList: res,
            });
          }}
          theme="secondary"
          data-tooltip="Stocks des lieux"
          data-tooltip-id="my-tooltip-inline"
          icon={({ className }) => <FaBuildingWheat className={className} />}
        />
      )} */}

      {urlPattern.test(location.pathname) && (
        <>
          {/* 
          //UNCOMENT THIS PART WHEN READY
          {currenShop?.multiLieu && (
            <Button
              className="shrink-0"
              size={size}
              onClick={(e) => setLieuStockModal(true)}
              theme="secondary"
              data-tooltip="Stocks des lieux"
              data-tooltip-id="my-tooltip-inline"
              icon={({ className }) => (
                <FaBuildingWheat className={className} />
              )}
            />
          )} */}
          <div className="relative">
            <div className="absolute w-full h-0.5 bg-slate-800 top-1/2 transform -translate-y-1/2 rotate-45" />

            <Button
              className="shrink-0"
              size={size}
              disabled={loading}
              onClick={async (e) => {
                e.stopPropagation();

                await etiqProducts([product as ProductType]);
              }}
              theme={product.prixModif ? "warning" : "secondary"}
              data-tooltip="Étiqueter produit"
              data-tooltip-id="my-tooltip-inline"
              icon={({ className }) => <TagIcon className={className} />}
            />
          </div>{" "}
          <Button
            theme="secondary"
            size={size}
            disabled={
              referGestion === "false" ||
              !functionsAuthorization[`Regul-${product.codeTypeProd}`].actif
            }
            onClick={() =>
              setRegulStockModal({
                open: true,
                product: product as ProductType,
              })
            }
            data-tooltip={
              referGestion === "true"
                ? "Régularisation de stock"
                : "Régularisation de stock impossible car cet article n'est pas référencé en gestion"
            }
            data-tooltip-id="my-tooltip-inline"
            icon={({ className }) => <ImCodepen className={className} />}
          />
        </>
      )}
      {window.top === window.self && (
        <Button
          disabled={
            referGestion === "false" ||
            product.referGestion === false ||
            product.qteDispoVente <= 0
          }
          authorized={
            functionsAuthorization[`PrepRet-${product.codeTypeProd}`] &&
            functionsAuthorization[`PrepRet-${product.codeTypeProd}`].actif
          }
          className="shrink-0"
          size={size}
          onClick={(e) => {
            e.stopPropagation();
            flushGlobalEffects();

            navigate(
              ROUTES.SupplierRetourOne.replace(/:id/, product.id).replace(
                /:type/,
                product.modeGest
              ),
              {
                state: { from: location.pathname },
              }
            );
          }}
          theme="secondary"
          data-tooltip="Retour fournisseur"
          data-tooltip-id="my-tooltip-inline"
          icon={({ className }) => <ReplyIcon className={className} />}
          shortcut={
            shortcuts
              ? [ShortcutMapping.products.start_supplier_return]
              : undefined
          }
        />
      )}

      <Button
        className="shrink-0"
        size={size}
        authorized={
          functionsAuthorization[`PrepCde-${product.codeTypeProd}`] &&
          functionsAuthorization[`PrepCde-${product.codeTypeProd}`].actif
        }
        onClick={async (e) => {
          e.stopPropagation();
          if (!product.referGestion)
            await ProductsApiClient.referenceProduct(product.id);
          navigate(
            ROUTES.SupplierCommandOne.replace(/:id/, product.id).replace(
              /:type/,
              product.modeGest
            ),
            {
              state: { from: location.pathname },
            }
          );
        }}
        theme="secondary"
        data-tooltip="Commande fournisseur"
        icon={({ className }) => <TruckIcon className={className} />}
        shortcut={
          shortcuts
            ? [ShortcutMapping.products.start_supplier_order]
            : undefined
        }
      />
      {window.top === window.self && (
        <Button
          className="shrink-0"
          size={size}
          authorized={cdeCliEnabled}
          onClick={async (e) => {
            e.stopPropagation();
            if (!product.referGestion)
              await ProductsApiClient.referenceProduct(product.id);
            const id = nanoid();
            addBasket({
              id,
              temporary: false,
              products: [
                {
                  product: product,
                  count: 1,
                },
              ],
            });
            setCurrent(id);
            navigate(ROUTES.BasketClientOrder.replace(":id", id));
          }}
          theme="secondary"
          data-tooltip="Passer commande client"
          icon={({ className }) => <ShoppingBagIcon className={className} />}
          shortcut={
            shortcuts
              ? [ShortcutMapping.products.start_client_order]
              : undefined
          }
        />
      )}

      {!basketSize && window.top === window.self && (
        <Button
          size={size}
          onClick={async (e) => {
            e.stopPropagation();
            await add(product);
          }}
          data-tooltip="Ajouter au panier"
          icon={({ className }) => <ShoppingCartIcon className={className} />}
          shortcut={
            shortcuts ? [ShortcutMapping.products.add_to_basket] : undefined
          }
        >
          Ajouter panier
        </Button>
      )}
      {(basketSize || 0) > 0 && window.top === window.self && (
        <div id={product.id} style={{ maxWidth: 128, minWidth: 96 }}>
          <InputCounter
            min={0}
            size={size}
            confirmOnZero
            deleteIconOnZero
            value={basketSize || 0}
            onChange={(value) => {
              if (value > basketSize!) add(product, value - basketSize!);
              else remove(product, basketSize! - value);
            }}
            shortcutAdd={
              shortcuts ? [ShortcutMapping.products.add_to_basket] : undefined
            }
            shortcutRemove={
              shortcuts ? [ShortcutMapping.products.remove_basket] : undefined
            }
          />
        </div>
      )}
    </>
  );
};

export const IsAside = ({ product }: { product: ProductTypeShort }) => {
  const { current } = useBaskets();
  const { products } = useBasket(current);
  const isAside = products.find((e) => e.product.id === product.id)?.aside;
  return (
    <>
      {isAside && (
        <div className="absolute top-0 left-0 h-full w-full bg-slate-600 bg-opacity-60 z-20">
          {" "}
        </div>
      )}
    </>
  );
};

export default function ProductCard({
  product,
}: {
  product: ProductTypeShort;
}) {
  const [dragOn, setDragOn] = useState(true);
  const { current } = useShopLocations();

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData("application/reactflow", JSON.stringify(product));
    e.dataTransfer.effectAllowed = "move";
  };
  return (
    <div
      className="flex grow h-48 relative "
      draggable={dragOn}
      onClick={() => {}}
      onDragStart={handleDragStart}
    >
      <div
        data-tooltip={product.liseraiTooltip}
        className={`absolute top-0 right-0 h-6 w-6 opacity-70 rounded-tr-lg ${product.liseraiColor}`}
        style={{ clipPath: "polygon(100% 0, 100% 100%, 0 0)" }}
      ></div>
      {product.poche && (
        <div className="absolute top-6 left-0 z-20">
          <Ribbon text="Poche" className="bg-blue-300  " />
        </div>
      )}
      <IsAside product={product} />
      <div className="shrink-0 h-full relative sm:w-32 w-24 py-2 flex justify-center border-r border-slate-200 dark:border-slate-800 bg-cover bg-center">
        <div className="px-2 flex items-center justify-center">
          <BookRender
            src={product.imageURL}
            productType={product.modeGest}
            textInfoPos="on-img"
          />
        </div>
        <div className="absolute bottom-5 sm:bottom-2 left-2 w-7 h-7 sm:w-9 sm:h-9 bg-white dark:bg-slate-900 flex items-center justify-center rounded-lg">
          {getProductTypeIcon(product.codeTypeProd)}
        </div>
      </div>
      <div className="grow flex flex-col py-3 px-1 overflow-hidden">
        <InfoSmallBold
          onMouseEnter={() => {
            setDragOn(false);
          }}
          onMouseLeave={() => {
            setDragOn(true);
          }}
          className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis cursor-text"
          data-html={true}
          data-tooltip={getCommonProductTooltip(product, product.tooltip)}
        >
          {product.designation}
        </InfoSmallBold>
        <InfoSmallBold
          onMouseEnter={() => {
            setDragOn(false);
          }}
          onMouseLeave={() => {
            setDragOn(true);
          }}
          className="block cursor-text"
        >
          {product.id}
        </InfoSmallBold>
        <div className="flex flex-col overflow-hidden my-2">
          {/* <InfoSmall>
              {product.qteDispoVente} disponible (
              {product.codeEtat === "S"
                ? `Supprimé: ${product.fournHabit}`
                : `${product.dispoHabit} - ${product.fournHabit}`}
              )
            </InfoSmall> */}
          {/* <InfoSmall>
              {!product.referGestion ||
              product.qteDispoVente === product.qteDispoVenteFutur
                ? `${product.qteDispoVente} dispo (${product.dispoHabit} ${product.fournHabit})`
                : `${product.qteDispoVente} dispo (${product.qteDispoVenteFutur} future - ${product.dispoHabit} ${product.fournHabit})`}
            </InfoSmall> */}
          <div className="flex flex-col">
            <InfoSmall className="whitespace-nowrap overflow-hidden text-ellipsis">
              {product.qteDispoVente} disponible à la vente{" "}
              {product.qteDispoVente !== product.qteDispoVenteFutur
                ? `(${product.qteDispoVenteFutur} future)`
                : ""}
            </InfoSmall>
            {product.dispoHabit && (
              <InfoSmall className="whitespace-nowrap overflow-hidden text-ellipsis">
                {product.dispoHabit} - {product.fournHabit}
              </InfoSmall>
            )}
            {product.libRayon && (
              <InfoSmall className="whitespace-nowrap overflow-hidden text-ellipsis cursor-text">
                Rayon: {product.libRayon}
              </InfoSmall>
            )}
          </div>
        </div>
        <BaseBold className="!text-blue-600">
          {`${formatAmount(
            product.prixPublic,
            current?.devisSymb,
            current?.devisNbDecim
          )}`}
          <InfoSmall className="text-center "> TTC</InfoSmall>
        </BaseBold>
        <div className="flex flex-row space-x-2 justify-start mt-3">
          <ProductActions product={product} size="xmd" />
        </div>
      </div>
    </div>
  );
}
